<template>
    <div class="wrap">
        <confirm-comp
            id="genreComp"
            :text1="'선택하신 장르로 큐레이션할 경우,'"
            :text2="'분위기가 일관되지 않을 수 있습니다.'"
            :text3="'선택하신 장르로 계속 하시겠습니까?'"
            v-if="isGenreComp"
            @close-modal="onCloseGenreModal"
            @confirm-modal="onConfirmGenreModal"
        />
        <alert-modal v-if="warningModal" :alertText="warningText" @closeModal="onCloseModal" />
        <alert-modal
            v-if="isOverGenreModal"
            :alertText="'선호장르는 최대 3개까지 선택가능합니다.'"
            @closeModal="onCloseOverGenreModal"
        />
        <select-vocal
            v-if="isSelectVocal"
            :isshowjazz="isShowJazz"
            :isshowclassic="isShowClasic"
            :classicvocalvalue="classicVocalValue"
            :jazzvocalvalue="jazzVocalValue"
            @save-vocal="onSaveVocal"
            @close-selectvocal="onSelectVocal"
        />
        <cancel-modal v-if="cancelStatus" />
        <cancel-checkmodal
            v-if="cancelCheckStatus"
            :curationinfo="curationObject"
            :isRegistCard="isRegistCard"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <!-- ################### PC ################### -->
        <div class="spaceinsert_pc">
            <curation-infopc
                v-if="showCurationInfo"
                :curationInfo="sendCurationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <section class="space_register_box scroll">
                <div class="space_register_title_box dp_flex flex_space_between">
                    <h1 class="space_register_title">스페이스 등록</h1>
                    <div @click="linkMySpace()" class="dp_flex align_center cursor">
                        <img src="/media/img/space/close.svg" alt="닫기" />
                    </div>
                </div>
                <h3 class="space_register_subtitle font_white">선호하는 장르를 알려주시겠어요?</h3>
                <div class="space_progress_bar_wrap">
                    <progress-bar :progresswidth="progressWidth" />
                </div>
                <ul class="dp_flex flex_column fade-up">
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in genre1" :key="item.id" @click="onClickGenre(item)">
                                <img class="space_genre_img cursor" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle cursor"
                                ></div>
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in genre2" :key="item.id" @click="onClickGenre(item)">
                                <img class="space_genre_img cursor" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle cursor"
                                ></div>
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in genre3" :key="item.id" @click="onClickGenre(item)">
                                <img class="space_genre_img cursor" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle cursor"
                                ></div>
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </div>
        <!-- ################### 모바일 ################### -->
        <div class="spaceinsert_mo">
            <curation-infomo
                v-if="showCurationInfo"
                :curationInfo="sendCurationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <!-- 모바일 헤더-->
            <mo-spaceheader @click-pre="onClickPre" @link-myspace="linkMySpace">
                <template #progressbar>
                    <progress-bar :progresswidth="progressWidth" />
                </template>
            </mo-spaceheader>
            <!-- 모바일 바디 -->
            <section class="mo_spaceinsert_body">
                <h3 class="mo_spaceinsert_title">선호하는 장르를 알려주시겠어요?</h3>
                <ul class="dp_flex flex_column fade-up">
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in moGenre1" :key="item.id">
                                <img class="space_genre_img cursor" @click="onClickGenre(item)" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    @click="onClickGenre(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle"
                                ></div>
                                <img
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    class="circle_hart"
                                    src="/media/img/space/hart.png"
                                    alt="하트"
                                />
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in moGenre2" :key="item.id">
                                <img class="space_genre_img cursor" @click="onClickGenre(item)" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    @click="onClickGenre(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle"
                                ></div>
                                <img
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    class="circle_hart"
                                    src="/media/img/space/hart.png"
                                    alt="하트"
                                />
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                    <li class="space_genre_list">
                        <ul class="dp_flex flex_space_between">
                            <li class="relative" v-for="item in moGenre3" :key="item.id">
                                <img class="space_genre_img cursor" @click="onClickGenre(item)" :src="item.imgUrl" :alt="item.koText" />

                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    @click="onClickGenre(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle"
                                ></div>
                                <img
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    class="circle_hart"
                                    src="/media/img/space/hart.png"
                                    alt="하트"
                                />
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul class="dp_flex flex_space_between space_genre_list_half">
                            <li class="relative" v-for="item in moGenre4" :key="item.id">
                                <img class="space_genre_img cursor" @click="onClickGenre(item)" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    @click="onClickGenre(item)"
                                    :id="'circle' + item.id"
                                    class="active_circle"
                                ></div>
                                <img
                                    v-if="selectGenre.includes(item.value) || isHasVocal(item)"
                                    class="circle_hart"
                                    src="/media/img/space/hart.png"
                                    alt="하트"
                                />
                                <span class="space_genre_name">{{ item.koText }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </div>
        <!-- footer -->
        <space-bottom
            :btndisabled="btnDisabled"
            :curationlist="bottomList"
            @show-curationinfo="onShowCurationinfo"
            @click-pre="onClickPre"
            @update-curation="onUpdateCuration"
        />
        <div class="space_notice_txt" :class="{ inacitve: isActive }">핑크색 장르 위주로 큐레이션 됩니다.</div>
    </div>
</template>
<script>
import { cloneDeep } from 'lodash-es';
import { getCurationInfo } from '@/service/api/playerApi';
import { updateCurationAllTemp, checkSubscribe, updateConfirmCuration } from '@/service/api/profileApi';
import { getMyCardInfo } from '@/service/api/productApi';
import { checkInstrumental } from '@/utils/Utils';
export default {
  name: 'SpaceInsertGenre',
  components: {
    'progress-bar': () => import(`@/components/space/ProgressBar.vue`),
    'space-bottom': () => import(`@/components/space/SpaceBottom.vue`),
    'mo-spaceheader': () => import(`@/components/common/MoSpaceHeader.vue`),
    'cancel-modal': () => import(`@/components/modal/Space/CancelModal.vue`),
    'cancel-checkmodal': () => import(`@/components/modal/Space/CancelCheckModal.vue`),
    'curation-infopc': () => import(`@/components/modal/Space/CurationInfo.vue`),
    'curation-infomo': () => import(`@/components/modal/Space/CurationInfoM.vue`),
    'select-vocal': () => import(`@/components/space/SelectVocal.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`),
    'confirm-comp': () => import(`@/components/modal/Space/ConfirmComp.vue`)
  },
  data () {
    return {
      genre: [],
      selectGenre: [],
      curationInfo: null,
      isActive: false,
      bottomList: [],
      spaceId: localStorage.getItem('spaceId'),
      cancelCheckStatus: false,
      cancelStatus: false,
      curationObject: null,
      isRegistCard: false,
      userId: this.$cookies.get('userId'),
      showCurationInfo: false,
      isSelectVocal: false,
      isShowJazz: false,
      isShowClasic: false,
      jazzVocalValue: -1,
      classicVocalValue: -1,
      warningModal: false,
      warningText: '',
      isGenreComp: false,
      tempvalue: -1,
      isOverGenreModal: false,
      sendCurationInfo: null,
      progressWidth: 0
    };
  },

  created () {
    this.setCurationInfo();
    this.setGenre();
    this.endNotice();
    this.checkRegistCard();
  },

  mounted () {},

  methods: {
    onCloseGenreModal () {
      this.tempvalue = -1;
      this.isGenreComp = false;
    },
    onConfirmGenreModal () {
      this.pushGenre(parseInt(this.tempvalue));
      this.isGenreComp = false;
    },
    onCloseModal () {
      this.warningModal = false;
      this.warningText = '';
    },
    onCloseOverGenreModal () {
      this.isOverGenreModal = false;
    },
    isHasVocal (item) {
      const { value } = item;
      if (parseInt(value) === 5 && this.jazzVocalValue !== -1) {
        //
        return true;
      }

      if (parseInt(value) === 11 && this.classicVocalValue !== -1) {
        //
        return true;
      }

      return false;
    },
    onDeleteSpace () {},
    onSelectVocal () {
      this.isSelectVocal = false;
    },
    async onSaveVocal (val) {
      const { jazzVocal, classicVocal } = val;
      const tuneY = [];
      const tuneN = [];
      // 재즈
      if (parseInt(jazzVocal) === 0) {
        // 장르에서 재즈 빼기
        tuneN.push(5);
      } else if (parseInt(jazzVocal) === 1) {
        // 장르에서 재즈 빼기
        tuneY.push(5);
      }

      // 클래식
      if (parseInt(classicVocal) === 0) {
        // 장르에서 클래식 빼기
        tuneN.push(11);
      } else if (parseInt(classicVocal) === 1) {
        // 장르에서 클래식 빼기
        tuneY.push(11);
      }

      // 장르 필터
      const genre = this.selectGenre
        .map(item => parseInt(item))
        .filter(filterItem => {
          if (this.isShowJazz && (parseInt(jazzVocal) === 0 || parseInt(jazzVocal) === 1)) {
            return filterItem !== 5;
          } else if (this.isShowJazz && parseInt(jazzVocal) === 2) {
            return 5;
          }
          return filterItem;
        })
        .filter(filterItem => {
          if (this.isShowClasic && (parseInt(classicVocal) === 0 || parseInt(classicVocal) === 1)) {
            return filterItem !== 11;
          } else if (this.isShowClasic && parseInt(classicVocal) === 2) {
            return 11;
          }
          return filterItem;
        })
        .sort((a, b) => a - b)
        .join(',');

      const sendObj = {
        genre,
        spaceId: this.spaceId,
        genreTuneY: tuneY.join(','),
        genreTuneN: tuneN.join(',')
      };
      try {
        const { data: curationTempData } = await updateCurationAllTemp(sendObj);
        const { resultCd } = curationTempData;
        if (resultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationInfo.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStorePeriod' }).catch(() => {});
          }
        }
      } catch (error) {
        console.error('error : ', error);
      } finally {
        this.isSelectVocal = false;
      }
    },
    checkRegistCard () {
      getMyCardInfo(this.userId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            result ? (this.isRegistCard = true) : (this.isRegistCard = false);
          } else {
            this.isRegistCard = false;
          }
        })
        .catch(() => {
          this.isRegistCard = false;
        });
    },
    onShowCurationinfo () {
      this.showCurationInfo = true;
    },
    onClickPre () {
      this.$router.push({ name: 'SpaceInsertStoreCountry' }).catch(() => {});
    },
    async onUpdateCuration () {
      const genre = this.selectGenre
        .map(item => parseInt(item))
        .sort((a, b) => a - b)
        .join(',');

      const sendObj = {
        genre,
        spaceId: this.spaceId
      };

      try {
        // 장르에 재즈(5),클래식(11)이 있을 경우
        const checkGenre = [5, 11];
        const findCheckGenre = checkGenre.some(ele => genre.includes(ele.toString()));
        if (findCheckGenre || this.jazzVocalValue !== -1 || this.classicVocalValue !== -1) {
          this.isShowJazz = false;
          this.isShowClasic = false;
          const findJazz = checkGenre.some(() => genre.includes('5'));
          const findClassic = checkGenre.some(() => genre.includes('11'));

          if (findJazz || this.jazzVocalValue !== -1) {
            this.isShowJazz = true;
          }

          if (findClassic || this.classicVocalValue !== -1) {
            this.isShowClasic = true;
          }
          this.isSelectVocal = true;
        } else {
          const { data: curationTempData } = await updateCurationAllTemp(sendObj);
          const { resultCd } = curationTempData;
          if (resultCd === '0000') {
            const { data: confirmCurationData } = await updateConfirmCuration(
              this.spaceId,
              this.curationInfo.curationId
            );
            const { resultCd: confirmCurationResultCd } = confirmCurationData;
            if (confirmCurationResultCd === '0000') {
              this.$router.push({ name: 'SpaceInsertStorePeriod' }).catch(() => {});
            }
          }
        }
      } catch (error) {
        console.error('error : ', error);
      }
    },
    setGenreValue (genre) {
      genre.forEach(genreItem => {
        const find = this.$store.getters['curation/getGenreValue'](genreItem);
        if (find) {
          this.selectGenre.push(find.value);
        }
      });
    },
    setVocal (genreTuneY, genreTuneN, genre) {
      this.jazzVocalValue = -1;
      this.classicVocal = -1;
      genreTuneY.forEach(item => {
        const findGenre = this.$store.getters['curation/getGenreValue'](item);
        if (findGenre) {
          const { value } = findGenre;
          if (value === 5) {
            this.jazzVocalValue = 1;
          }
          if (value === 11) {
            this.classicVocalValue = 1;
          }
        }
      });

      genreTuneN.forEach(item => {
        const findGenre = this.$store.getters['curation/getGenreValue'](item);
        if (findGenre) {
          const { value } = findGenre;
          if (value === 5) {
            this.jazzVocalValue = 0;
          }
          if (value === 11) {
            this.classicVocalValue = 0;
          }
        }
      });

      genre.forEach(item => {
        const findGenre = this.$store.getters['curation/getGenreValue'](item);
        if (findGenre) {
          const { value } = findGenre;
          if (value === 5) {
            this.jazzVocalValue = 2;
          }
          if (value === 11) {
            this.classicVocalValue = 2;
          }
        }
      });
    },
    async setCurationInfo () {
      try {
        const { data } = await getCurationInfo(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.curationInfo = result;
          this.sendCurationInfo = cloneDeep(result);
          this.sendCurationInfo.genre = [];
          const {
            spaceType,
            color,
            material,
            target,
            domesticRate,
            genre,
            genreTuneY,
            genreTuneN,
            carPurpose
          } = result;
          let genreTotal = [];
          if (genre) {
            genreTotal = [...genreTotal, ...genre];
          }

          if (genreTuneN) {
            genreTotal = [...genreTotal, ...genreTuneN];
          }

          if (genreTuneY) {
            genreTotal = [...genreTotal, ...genreTuneY];
          }
          this.setGenreValue(genreTotal || []);
          this.setVocal(genreTuneY || [], genreTuneN || [], genre || []);

          // 국내곡 비율
          if (domesticRate) {
            //
            const domestictRateFind = this.$store.getters['curation/getDomesticRateValue'](parseInt(domesticRate));

            this.bottomList.push({
              cssclass: 'space_curation_info_box img first domesticatrate',
              url: domestictRateFind.imgUrl
            });
          }
          if (spaceType === 'Store') {
            this.progressWidth = 72;

            // 고객층
            if (target) {
              target.forEach(ele => {
                const findTarget = this.$store.getters['curation/getTargetValue'](ele);
                if (findTarget) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box img',
                    url: findTarget.imgUrl
                  });
                }
              });
            }

            // 소재
            if (material) {
              material.forEach(ele => {
                const findMaterial = this.$store.getters['curation/getMaterialValue'](ele);
                if (findMaterial) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box material',
                    url: findMaterial.materialNm
                  });
                }
              });
            }
            // 색상
            if (color) {
              const findColor = this.$store.getters['curation/getColorItem'](color);
              this.bottomList.push({
                cssclass: 'space_curation_info_box color',
                url: `${findColor.img}`
              });
            }
          } else {
            this.progressWidth = 64;
            // 이용목적
            if (carPurpose) {
              carPurpose.forEach(ele => {
                const carpurposeFind = this.$store.getters['curation/getCarPurPoseValue'](ele);
                if (carpurposeFind) {
                  this.bottomList.push({
                    cssclass: 'space_curation_info_box img',
                    url: carpurposeFind.imgUrl
                  });
                }
              });
            }
          }
        }
      } catch (error) {
        console.error('error : ', error);
      }
    },
    setGenre () {
      const clone = cloneDeep(this.$store.getters['curation/getGenreAll']) || [];
      this.genre = clone;
    },
    pushGenre (value) {
      const find = this.selectGenre.findIndex(item => item === value);

      if (find > -1) {
        this.selectGenre.splice(find, 1);
        if (value === 5 && this.jazzVocalValue !== -1) {
          this.jazzVocalValue = -1;
          return true;
        }

        if (value === 11 && this.classicVocalValue !== -1) {
          this.classicVocalValue = -1;
          return true;
        }
      } else {
        if (parseInt(this.curationInfo.domesticRate) === 0 && value === 10) {
          this.warningText = `해외곡만 선호하시는 경우 <br> 트로트는 선택 불가합니다.`;
          this.warningModal = true;
          return true;
        }

        this.selectGenre.push(parseInt(value));
      }
    },
    onClickGenre (val) {
      const { value } = val;
      if (this.selectGenre.length < 3) {
        if (checkInstrumental(this.selectGenre, value)) {
          this.tempvalue = parseInt(value);
          this.isGenreComp = true;
          return true;
        }
        this.pushGenre(value);
      } else {
        const find = this.selectGenre.find(item => item === value);
        if (find) {
          const findIdx = this.selectGenre.findIndex(item => item === value);

          if (find === 5 && this.jazzVocalValue !== -1) {
            this.jazzVocalValue = -1;
          }

          if (find === 11 && this.classicVocalValue !== -1) {
            this.classicVocalValue = -1;
          }
          this.selectGenre.splice(findIdx, 1);
        } else {
          this.isOverGenreModal = true;
        }
      }
    },
    async linkMySpace () {
      try {
        const { data } = await checkSubscribe(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          if (result === 'S') {
            this.cancelCheckStatus = true;
            // 장르 값 저장
            this.curationObject = {
              spaceId: this.spaceId,
              genre: this.selectGenre
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .join(',')
            };
          } else {
            this.$router.push({ name: 'Player' }).catch(() => {});
          }
        }
      } catch (error) {
        console.error('linkMySpace error : ', error);
      }
    },
    endNotice () {
      setTimeout(() => {
        this.isActive = true;
      }, 3500);
    }
  },
  computed: {
    btnDisabled () {
      if (this.selectGenre.length > 0 || this.classicVocalValue !== -1 || this.jazzVocalValue !== -1) {
        return false;
      }
      return true;
    },
    genre1 () {
      return this.genre.slice(0, 5);
    },
    genre2 () {
      return this.genre.slice(5, 10);
    },
    genre3 () {
      return this.genre.slice(10);
    },
    moGenre1 () {
      return this.genre.slice(0, 3);
    },
    moGenre2 () {
      return this.genre.slice(3, 6);
    },
    moGenre3 () {
      return this.genre.slice(6, 9);
    },
    moGenre4 () {
      return this.genre.slice(9);
    }
  }
};
</script>
<style scoped src="@/assets/css/space/spaceinsert.css"></style>
<style scoped src="@/assets/css/space/spacegenre.css"></style>
